import { useQuery } from "@tanstack/react-query";
import { Card, Row, Col } from "antd";
import { LoaderIconUtils } from "../../../utils/LoaderIconUtils";
import { fetchTutorPaymentSummary } from "../../../api/AtheleService";

export const UserProfileLogic = () => {
  const { data: summary, isLoading, isError, error } = useQuery({
    queryKey: ["tutorPaymentSummary"],
    queryFn: fetchTutorPaymentSummary, // Llamamos al servicio centralizado
  });

  if (isLoading) return <LoaderIconUtils />;
  if (isError) return <div>Error: {error.message}</div>;

  return (
    <div style={{ padding: "20px" }}>
      <h4 className="text-gray-500">Resumen de Pagos</h4>
      <Row gutter={[16, 16]} className="align-middle mt-4 items-center">
        <Col span={6}>
          <Card title="Pagos Realizados">
            <p>Elementos: {summary.paid_count}</p>
            <p>Monto: ${summary.total_paid} MXN</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Pagos Pendientes">
            <p>Elementos: {summary.pending_count}</p>
            <p>Monto: ${summary.total_pending} MXN</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Próximos Pagos">
            <p>Elementos: {summary.upcoming_count}</p>
            <p>Monto: ${summary.total_upcoming} MXN</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Monto Total">
            <p>${summary.total_paid + summary.total_pending} MXN</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
